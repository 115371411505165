









































































import SimpleMessageWrapper from '@/components/SimpleMessageWrapper.vue';
import {Component, Mixins, Watch} from 'vue-property-decorator';
import BaseEditComponent from '@/components/BaseEditComponent.vue';
import {BasicCrudMixin} from '@/mixins/BasicCrudMixin';
import {GenericApi} from '@/api/generic';
import {maxDouble, minDouble, required} from '@/utils/validation';
import {Idable} from '@/models/Idable';
import {ISbiAfdeling} from '@/models/constants/ISbiAfdeling';
import {ISbiTopSector} from '@/models/constants/ISbiTopSector';
import {ISbiKlasse} from '@/models/constants/ISbiKlasse';
import {ISbiGroep} from '@/models/constants/ISbiGroep';
import {Pagination} from '@/utils/api-tools/pagination';
import {ISbiSectie} from '@/models/constants/ISbiSectie';
import {ISbi} from '@/models/constants/ISbi';

@Component({
    filters: {},
    components: {
        BaseEditComponent,
        SimpleMessageWrapper,
    },
})
export default class SbiEdit extends Mixins(BasicCrudMixin) {
    public modelObject: ISbi | null = null;
    public sbiAfdelingen: ISbiAfdeling[] = [];
    public sbiGroepen: ISbiGroep[] = [];
    public sbiKlasses: ISbiKlasse[] = [];
    public sbiSecties: ISbiSectie[] = [];
    public sbiTopSectoren: ISbiTopSector[] = [];

    public getApi(): GenericApi<Idable> {
        return this.$api.sbi;
    }

    @Watch('modelObject', {deep: true})
    public onModelChange() {
        this.updateOmschrijvingen();
    }

    public updateOmschrijvingen() {
        if (this.modelObject) {
            this.modelObject.afdelingOmschrijving = this.modelObject.afdeling
                ? this.sbiAfdelingen.find((a) => a.code === this.modelObject?.afdeling)?.omschrijving ?? null
                : null;
            this.modelObject.groepOmschrijving = this.modelObject.groep
                ? this.sbiGroepen.find((a) => a.code === this.modelObject?.groep)?.omschrijving ?? null
                : null;
            this.modelObject.klasseOmschrijving = this.modelObject.klasse
                ? this.sbiKlasses.find((a) => a.code === this.modelObject?.klasse)?.omschrijving ?? null
                : null;
            this.modelObject.sectieOmschrijving = this.modelObject.sectie
                ? this.sbiSecties.find((a) => a.code === this.modelObject?.sectie)?.omschrijving ?? null
                : null;
        }
    }

    public get rules() {
        return {
            code: [required],
            omschrijving: [required],
            naam: [required],
            malePercentage: [required, minDouble(0), maxDouble(100)],
            sectionLetter: [required],
        };
    }

    public get femaleRatio() {
        const malePercentage = (this.modelObject as any)?.malePercentage || 0;
        return malePercentage >= 0 && malePercentage <= 100 ? 100 - malePercentage : null;
    }

    public buildEmptyModelData(): Idable {
        return {
            naam: null,
            omschrijving: null,
            code: null,
            malePercentage: null,
            sectie: null,
        } as unknown as Idable;
    }

    public async beforeMount(): Promise<void> {
        const sbiAfdelingenResponse = await this.$api.sbiAfdeling.list(new Pagination(0, -1), []);
        const sbiGroepenResponse = await this.$api.sbiGroep.list(new Pagination(0, -1), []);
        const sbiKlassesResponse = await this.$api.sbiKlasse.list(new Pagination(0, -1), []);
        const sbiTopSectorenResponse = await this.$api.sbiTopSector.list(new Pagination(0, -1), []);
        const sbiSectieResponse = await this.$api.sbiSectie.list(new Pagination(0, -1), []);
        this.sbiAfdelingen = sbiAfdelingenResponse.data!.content;
        this.sbiGroepen = sbiGroepenResponse.data!.content;
        this.sbiKlasses = sbiKlassesResponse.data!.content;
        this.sbiTopSectoren = sbiTopSectorenResponse.data!.content;
        this.sbiSecties = sbiSectieResponse.data!.content;
        this.updateOmschrijvingen();
    }
}
