import Vue from 'vue';
import Router, { RouteConfig } from 'vue-router';
import Home from './views/Home.vue';
import Login from '@/views/Login.vue';
import AppBase from '@/views/AppBase.vue';
import i18n from '@/plugins/i18n';
import AccessDenied from '@/views/AccessDenied.vue';
import UserList from '@/views/users/UserList.vue';
import UserEdit from '@/views/users/UserEdit.vue';
import SbiList from '@/views/constants/sbi/SbiList.vue';
import SbiEdit from '@/views/constants/sbi/SbiEdit.vue';
import GebruiksfunctieList from '@/views/constants/gebruiksfunctie/GebruiksfunctieList.vue';
import GebruiksfunctieEdit from '@/views/constants/gebruiksfunctie/GebruiksfunctieEdit.vue';
import GemeenteEdit from '@/views/constants/gemeente/GemeenteEdit.vue';
import GemeenteList from '@/views/constants/gemeente/GemeenteList.vue';
import KvkRedenInList from '@/views/constants/kvkredenin/KvkRedenInList.vue';
import KvkRedenInEdit from '@/views/constants/kvkredenin/KvkRedenInEdit.vue';
import KvkRedenUitList from '@/views/constants/kvkredenuit/KvkRedenUitList.vue';
import KvkRedenUitEdit from '@/views/constants/kvkredenuit/KvkRedenUitEdit.vue';
import PlaatsnaamList from '@/views/constants/plaatsnaam/PlaatsnaamList.vue';
import PlaatsnaamEdit from '@/views/constants/plaatsnaam/PlaatsnaamEdit.vue';
import RechtsVormList from '@/views/constants/rechtsvorm/RechtsvormList.vue';
import RechtsvormEdit from '@/views/constants/rechtsvorm/RechtsvormEdit.vue';
import TypePandList from '@/views/constants/typepand/TypePandList.vue';
import TypePandEdit from '@/views/constants/typepand/TypePandEdit.vue';
import UitzetCodeList from '@/views/constants/uitzetcode/UitzetCodeList.vue';
import UitzetCodeEdit from '@/views/constants/uitzetcode/UitzetCodeEdit.vue';
import VestigingTypeEdit from '@/views/constants/vestigingtype/VestigingTypeEdit.vue';
import VestigingTypeList from '@/views/constants/vestigingtype/VestigingTypeList.vue';
import WijzeBinnenList from '@/views/constants/wijzebinnen/WijzeBinnenList.vue';
import WijzeBinnenEdit from '@/views/constants/wijzebinnen/WijzeBinnenEdit.vue';
import WijzeEindeList from '@/views/constants/wijzeeinde/WijzeEindeList.vue';
import WijzeEindeEdit from '@/views/constants/wijzeeinde/WijzeEindeEdit.vue';
import WijzeStartList from '@/views/constants/wijzestart/WijzeStartList.vue';
import XyKwaliteitList from '@/views/constants/xykwaliteit/XyKwaliteitList.vue';
import WijzeStartEdit from '@/views/constants/wijzestart/WijzeStartEdit.vue';
import XyKwaliteitEdit from '@/views/constants/xykwaliteit/XyKwaliteitEdit.vue';
import VestigingList from '@/views/vestigingen/VestigingList.vue';
import VestigingEdit from '@/views/vestigingen/VestigingEdit.vue';
import ImportTemplateList from '@/views/imports/ImportTemplateList.vue';
import ImportTemplateEdit from '@/views/imports/ImportTemplateEdit.vue';
import RegisterList from '@/views/register/RegisterList.vue';
import RegisterEdit from '@/views/register/RegisterEdit.vue';
import MeetperiodeList from '@/views/constants/meetperiode/MeetperiodeList.vue';
import MeetperiodeEdit from '@/views/constants/meetperiode/MeetperiodeEdit.vue';
import ImportRunCreate from '@/views/imports/ImportRunCreate.vue';
import ImportRunList from '@/views/imports/ImportRunList.vue';
import ExportRunCreate from '@/views/exports/ExportRunCreate.vue';
import ExportRunCreateFromTemplate from '@/views/exports/ExportRunCreateFromTemplate.vue';
import ExportRunList from '@/views/exports/ExportRunList.vue';
import OverheidsCodeList from '@/views/constants/overheidscode/OverheidsCodeList.vue';
import OverheidsCodeEdit from '@/views/constants/overheidscode/OverheidsCodeEdit.vue';
import KvkUploadRunList from '@/views/kvk-upload-run/KvkUploadRunList.vue';
import KvkUploadRunNew from '@/views/kvk-upload-run/KvkUploadRunNew.vue';
import KvkUploadRunView from '@/views/kvk-upload-run/KvkUploadRunView.vue';
import GrootteklasseList from '@/views/constants/grootteklasse/GrootteklasseList.vue';
import GrootteklasseEdit from '@/views/constants/grootteklasse/GrootteklasseEdit.vue';
import KvkList from '@/views/kvk/KvkList.vue';
import ResetData from '@/views/ResetData.vue';
import CbEdit from '@/views/cb/CbEdit.vue';
import CbList from '@/views/cb/CbList.vue';
import KvkManualProcessing from '@/views/kvk/KvkManualProcessing.vue';
import MfaLogin from '@/views/MfaLogin.vue';
import MfaManage from '@/views/MfaManage.vue';
import BagImport from '@/views/bag/BagImport.vue';
import { Role } from '@/models/IGebruiker';
import BuurtList from '@/views/constants/buurt/BuurtList.vue';
import BuurtEdit from '@/views/constants/buurt/BuurtEdit.vue';
import WijkList from '@/views/constants/wijk/WijkList.vue';
import WijkEdit from '@/views/constants/wijk/WijkEdit.vue';
import BedrijventerreinList from '@/views/constants/bedrijventerrein/BedrijventerreinList.vue';
import BedrijventerreinEdit from '@/views/constants/bedrijventerrein/BedrijventerreinEdit.vue';
import ShapeImport from '@/views/shape/ShapeImport.vue';
import MutatieList from '@/views/mutatie/MutatieList.vue';
import EnqueteTypeList from '@/views/constants/enquetetype/EnqueteTypeList.vue';
import EnqueteTypeEdit from '@/views/constants/enquetetype/EnqueteTypeEdit.vue';
import BehandelingTypeList from '@/views/constants/behandelingtype/BehandelingTypeList.vue';
import BehandelingTypeEdit from '@/views/constants/behandelingtype/BehandelingTypeEdit.vue';
import KvkSignalView from '@/views/kvk-signal/KvkSignalView.vue';
import KvkSignalList from '@/views/kvk-signal/KvkSignalList.vue';
import KvkSignalVestigingView from '@/views/kvk-signal-vestiging/KvkSignalVestigingView.vue';
import KvkSignalVestigingList from '@/views/kvk-signal-vestiging/KvkSignalVestigingList.vue';
import ArbeidsmarktRegioList from '@/views/constants/arbeidsmarktregio/ArbeidsmarktRegioList.vue';
import ArbeidsmarktRegioEdit from '@/views/constants/arbeidsmarktregio/ArbeidsmarktRegioEdit.vue';
import CoropGebiedList from '@/views/constants/coropgebied/CoropGebiedList.vue';
import CoropGebiedEdit from '@/views/constants/coropgebied/CoropGebiedEdit.vue';
import ProvincieCodeList from '@/views/constants/provinciecode/ProvincieCodeList.vue';
import ProvincieCodeEdit from '@/views/constants/provinciecode/ProvincieCodeEdit.vue';
import SbiAfdelingList from '@/views/constants/sbiafdeling/SbiAfdelingList.vue';
import SbiAfdelingEdit from '@/views/constants/sbiafdeling/SbiAfdelingEdit.vue';
import SbiGroepList from '@/views/constants/sbigroep/SbiGroepList.vue';
import SbiGroepEdit from '@/views/constants/sbigroep/SbiGroepEdit.vue';
import SbiKlasseList from '@/views/constants/sbiklasse/SbiKlasseList.vue';
import SbiKlasseEdit from '@/views/constants/sbiklasse/SbiKlasseEdit.vue';
import SbiTopSectorList from '@/views/constants/sbitopsector/SbiTopSectorList.vue';
import SbiTopSectorEdit from '@/views/constants/sbitopsector/SbiTopSectorEdit.vue';
import ToeristenGebiedList from '@/views/constants/toeristengebied/ToeristenGebiedList.vue';
import ToeristenGebiedEdit from '@/views/constants/toeristengebied/ToeristenGebiedEdit.vue';
import VeiligheidsRegioList from '@/views/constants/veiligheidsregio/VeiligheidsRegioList.vue';
import VeiligheidsRegioEdit from '@/views/constants/veiligheidsregio/VeiligheidsRegioEdit.vue';
import SbiSectieList from '@/views/constants/sectie/SbiSectieList.vue';
import SbiSectieEdit from '@/views/constants/sectie/SbiSectieEdit.vue';

Vue.use(Router);
const $t = i18n.t.bind(i18n);

const users: RouteConfig[] = [
    {
        path: '/gebruikers',
        name: 'users',
        components: {
            default: UserList,
        },
        meta: {publicName: 'Gebruikers', roles: [], indexRouteName: 'users', editRouteName: 'userEdit'},
    },
    {
        path: '/gebruikers/nieuw',
        name: 'userCreate',
        components: {
            default: UserEdit,
        },
        meta: {publicName: 'Gebruiker aanmaken', roles: [], indexRouteName: 'users', editRouteName: 'userEdit'},
    },
    {
        path: '/gebruikers/:id',
        name: 'userEdit',
        components: {
            default: UserEdit,
        },
        meta: {publicName: 'Gebruiker bewerken', roles: [], indexRouteName: 'users', editRouteName: 'userEdit'},
    },
];

const vestigingen: RouteConfig[] = [
    {
        path: '/vestigingen',
        name: 'vestigingen',
        components: {
            default: VestigingList,
        },
        meta: {publicName: 'Vestigingen', title: 'Vestigingen Overzicht - LISA', roles: [], indexRouteName: 'vestigingen', editRouteName: 'vestigingEdit'},
    },
    {
        path: '/vestigingen/new',
        name: 'vestigingCreate',
        components: {
            default: VestigingEdit,
        },
        meta: {publicName: 'Vestiging aanmaken', roles: [], indexRouteName: 'vestigingen', editRouteName: 'vestigingEdit'},
    },
    {
        path: '/vestigingen/:id',
        name: 'vestigingEdit',
        components: {
            default: VestigingEdit,
        },
        meta: {publicName: 'Vestiging bewerken', roles: [], indexRouteName: 'vestigingen', editRouteName: 'vestigingEdit'},
    },
];

const registers: RouteConfig[] = [
    {
        path: '/registers',
        name: 'registers',
        components: {
            default: RegisterList,
        },
        meta: {publicName: 'Registers', roles: [], indexRouteName: 'registers', editRouteName: 'registersEdit'},
    },
    {
        path: '/registers/new',
        name: 'registersCreate',
        components: {
            default: RegisterEdit,
        },
        meta: {publicName: 'Register aanmaken', roles: [], indexRouteName: 'registers', editRouteName: 'registersEdit'},
    },
    {
        path: '/registers/:id',
        name: 'registersEdit',
        components: {
            default: RegisterEdit,
        },
        meta: {publicName: 'Register bewerken', roles: [], indexRouteName: 'registers', editRouteName: 'registersEdit'},
    },
];

const cbs: RouteConfig[] = [
    {
        path: '/cb-list',
        name: 'cbList',
        components: {
            default: CbList,
        },
        meta: {publicName: 'Centrale berichtgever', roles: [], indexRouteName: 'cb', editRouteName: 'cbEdit'},
    },
    {
        path: '/cb/new',
        name: 'cbCreate',
        components: {
            default: CbEdit,
        },
        meta: {publicName: 'Centrale berichtgever aanmaken', roles: [], indexRouteName: 'cb', editRouteName: 'cbEdit'},
    },
    {
        path: '/cb/:id',
        name: 'cbEdit',
        components: {
            default: CbEdit,
        },
        meta: {publicName: 'Centrale berichtgever bewerken', roles: [], indexRouteName: 'cb', editRouteName: 'cbEdit'},
    },
];

const importTemplates: RouteConfig[] = [
    {
        path: '/import-templates',
        name: 'importTemplates',
        components: {
            default: ImportTemplateList,
        },
        meta: {publicName: 'Import sjablonen', roles: [], indexRouteName: 'import-templates', editRouteName: 'importTemplateEdit'},
    },
    {
        path: '/import-templates/new',
        name: 'importTemplateCreate',
        components: {
            default: ImportTemplateEdit,
        },
        meta: {publicName: 'Import sjabloon aanmaken', roles: [], indexRouteName: 'import-templates', editRouteName: 'importTemplateEdit'},
    },
    {
        path: '/import-templates/:id',
        name: 'importTemplateEdit',
        components: {
            default: ImportTemplateEdit,
        },
        meta: {publicName: 'Import sjabloon bewerken', roles: [], indexRouteName: 'import-templates', editRouteName: 'importTemplateEdit'},
    },
];

const imports: RouteConfig[] = [
    {
        path: '/import/new',
        name: 'importUpload',
        components: {
            default: ImportRunCreate,
        },
        meta: {publicName: 'Importeren', roles: [], indexRouteName: 'import-upload', editRouteName: null},
    },
    {
        path: '/imports',
        name: 'importList',
        components: {
            default: ImportRunList,
        },
        meta: {publicName: 'Importeren', roles: [], indexRouteName: 'imports', editRouteName: null},
    },
];

const mutaties: RouteConfig[] = [
    {
        path: '/mutaties',
        name: 'mutaties',
        components: {
            default: MutatieList,
        },
        meta: {publicName: 'Mutatie historie', roles: [], indexRouteName: 'mutaties', editRouteName: null},
    },
];

const exports: RouteConfig[] = [
    {
        path: '/exports',
        name: 'exportList',
        components: {
            default: ExportRunList,
        },
        meta: {publicName: 'Exporteren', roles: [], indexRouteName: 'exports', editRouteName: 'exportEdit'},
    },
    {
        path: '/export/new',
        name: 'exportCreate',
        components: {
            default: ExportRunCreate,
        },
        meta: {publicName: 'Exporteren', roles: [], indexRouteName: 'exports', editRouteName: 'exportEdit'},
    },
    {
        path: '/export-template/new',
        name: 'templateCreate',
        components: {
            default: ExportRunCreate,
        },
        meta: {publicName: 'Exporteren', roles: [], indexRouteName: 'exports', editRouteName: 'exportEdit'},
    },
    {
        path: '/export/:id',
        name: 'exportEdit',
        components: {
            default: ExportRunCreate,
        },
        meta: {publicName: 'Exporteren', roles: [], indexRouteName: 'exports', editRouteName: 'exportEdit'},
    },
    {
        path: '/export-template-edit/:id',
        name: 'templateEdit',
        components: {
            default: ExportRunCreate,
        },
        meta: {publicName: 'Exporteren', roles: [], indexRouteName: 'exports', editRouteName: 'exportEdit'},
    },
    {
        path: '/export-template/:id',
        name: 'exportCreateTemplate',
        components: {
            default: ExportRunCreateFromTemplate,
        },
        meta: {publicName: 'Exporteren', roles: [], indexRouteName: 'exports', editRouteName: 'exportEdit'},
    },
];


const kvk: RouteConfig[] = [
    {
        path: '/kvk-upload-run/new',
        name: 'kvk_upload_run_new',
        components: {
            default: KvkUploadRunNew,
        },
        meta: {
            publicName: 'Mutatieverwerking',
            roles: [],
            indexRouteName: 'kvk_upload_run_list',
            editRouteName: 'kvk_upload_run_view',
        },
    },
    {
        path: '/kvk-upload-run/list',
        name: 'kvk_upload_run_list',
        components: {
            default: KvkUploadRunList,
        },
        meta: {
            publicName: 'Mutatieverwerking',
            roles: [],
            indexRouteName: 'kvk_upload_run_list',
            editRouteName: 'kvk_upload_run_view',
        },
    },
    {
        path: '/kvk-upload-run/:id',
        name: 'kvk_upload_run_view',
        components: {
            default: KvkUploadRunView,
        },
        meta: {
            publicName: 'Mutatieverwerking',
            roles: [],
            indexRouteName: 'kvk_upload_run_list',
            editRouteName: 'kvk_upload_run_view',
        },
    },
    {
        path: '/kvk-list',
        name: 'kvk_list',
        components: {
            default: KvkList,
        },
        meta: {
            publicName: 'KvK gegevens',
            title: 'KvK Overzicht - LISA',
            roles: [],
            indexRouteName: 'kvk_list',
            editRouteName: 'kvk_listEdit',
        },
    },
    {
        path: '/kvk-manual-list',
        name: 'kvk_manual_list',
        components: {
            default: KvkManualProcessing,
        },
        meta: {
            publicName: 'KvK - handmatige verwerking',
            roles: [],
            indexRouteName: 'kvk_manual_list',
            editRouteName: null,
        },
    },
];

const kvkSignals: RouteConfig[] = [
    {
        path: '/kvk-signal',
        name: 'kvk_signals_list',
        components: {
            default: KvkSignalList,
        },
        meta: {
            publicName: 'KvK Signaal',
            roles: [],
            indexRouteName: 'kvk_signals_list',
            editRouteName: 'kvk_signal_view',
        },
    },
    {
        path: '/kvk-signal/:id',
        name: 'kvk_signal_view',
        components: {
            default: KvkSignalView,
        },
        meta: {
            publicName: 'KvK Signaal',
            roles: [],
            indexRouteName: 'kvk_signals_list',
            editRouteName: 'kvk_signal_view',
        },
    },
];


const kvkSignalVestiging: RouteConfig[] = [
    {
        path: '/kvk-signal-vestiging',
        name: 'kvk_signal_vestiging_list',
        components: {
            default: KvkSignalVestigingList,
        },
        meta: {
            publicName: 'KvK Signaal bij Vestiging',
            roles: [],
            indexRouteName: 'kvk_signal_vestiging_list',
            editRouteName: 'kvk_signal_vestiging_view',
        },
    },
    {
        path: '/kvk-signal-vestiging/:id',
        name: 'kvk_signal_vestiging_view',
        components: {
            default: KvkSignalVestigingView,
        },
        meta: {
            publicName: 'KvK Signaal bij Vestiging',
            roles: [],
            indexRouteName: 'kvk_signal_vestiging_list',
            editRouteName: 'kvk_signal_vestiging_view',
        },
    },
];

const bag: RouteConfig[] = [
    {
        path: '/bag-import',
        name: 'bag_import',
        component: BagImport,
        meta: {
            publicName: 'BAG gegevens',
            roles: [Role.ADMIN, Role.SUPER_ADMIN],
            indexRouteName: 'bag_import',
            editRouteName: 'bag_importEdit',
        },
    },
];

const shape: RouteConfig[] = [
    {
        path: '/shape-import',
        name: 'shape_import',
        component: ShapeImport,
        meta: {
            publicName: 'Shapefile gegevens',
            roles: [Role.ADMIN, Role.SUPER_ADMIN],
            indexRouteName: 'shape_import',
            editRouteName: 'shape_importEdit',
        },
    },
];

const constantCrudRoutes = [
    { name: 'sbi', publicName: 'SBI', list: SbiList, edit: SbiEdit },
    { name: 'grootteklasse', publicName: 'Grootteklasse', list: GrootteklasseList, edit: GrootteklasseEdit },
    { name: 'gebruiksfunctie', publicName: 'Gebruiksfunctie', list: GebruiksfunctieList, edit: GebruiksfunctieEdit },
    { name: 'gemeente', publicName: 'Gemeente', list: GemeenteList, edit: GemeenteEdit },
    { name: 'kvk-reden-in', publicName: 'KvK reden in', list: KvkRedenInList, edit: KvkRedenInEdit },
    { name: 'kvk-reden-uit', publicName: 'KvK reden uit', list: KvkRedenUitList, edit: KvkRedenUitEdit },
    { name: 'plaatsnaam', publicName: 'Plaatsnaam', list: PlaatsnaamList, edit: PlaatsnaamEdit },
    { name: 'rechtsvorm', publicName: 'Rechtsvorm', list: RechtsVormList, edit: RechtsvormEdit },
    { name: 'type-pand', publicName: 'Type pand', list: TypePandList, edit: TypePandEdit },
    { name: 'buurt', publicName: 'Buurt', list: BuurtList, edit: BuurtEdit },
    { name: 'wijk', publicName: 'Wijk', list: WijkList, edit: WijkEdit },
    { name: 'bedrijventerrein', publicName: 'Bedrijventerrein', list: BedrijventerreinList,
        edit: BedrijventerreinEdit },
    { name: 'uitzet-code', publicName: 'Uitzet code', list: UitzetCodeList, edit: UitzetCodeEdit },
    { name: 'vestiging-type', publicName: 'Vestiging type', list: VestigingTypeList, edit: VestigingTypeEdit },
    { name: 'wijze-binnen', publicName: 'Wijze binnen', list: WijzeBinnenList, edit: WijzeBinnenEdit },
    { name: 'wijze-einde', publicName: 'Wijze einde', list: WijzeEindeList, edit: WijzeEindeEdit },
    { name: 'wijze-start', publicName: 'Wijze start', list: WijzeStartList, edit: WijzeStartEdit },
    { name: 'xy-kwaliteit', publicName: 'XY kwaliteit', list: XyKwaliteitList, edit: XyKwaliteitEdit },
    { name: 'meetperiode', publicName: 'Meetperiode', list: MeetperiodeList, edit: MeetperiodeEdit },
    { name: 'overheids-code', publicName: 'Overheids code', list: OverheidsCodeList, edit: OverheidsCodeEdit },
    { name: 'enquete-type', publicName: 'Enquete type', list: EnqueteTypeList, edit: EnqueteTypeEdit },
    { name: 'behandeling-type', publicName: 'Behandeling type', list: BehandelingTypeList, edit: BehandelingTypeEdit },
    { name: 'arbeidsmarkt-regio', publicName: 'Arbeidsmarktregio',
        list: ArbeidsmarktRegioList, edit: ArbeidsmarktRegioEdit },
    { name: 'corop-gebied', publicName: 'Corop gebied', list: CoropGebiedList, edit: CoropGebiedEdit },
    { name: 'provincie-code', publicName: 'Provinciecode', list: ProvincieCodeList, edit: ProvincieCodeEdit },
    { name: 'sbi-afdeling', publicName: 'Sbi afdeling', list: SbiAfdelingList, edit: SbiAfdelingEdit },
    { name: 'sbi-groep', publicName: 'Sbi groep', list: SbiGroepList, edit: SbiGroepEdit },
    { name: 'sbi-klasse', publicName: 'Sbi klasse', list: SbiKlasseList, edit: SbiKlasseEdit },
    { name: 'sbi-top-sector', publicName: 'Sbi topsector', list: SbiTopSectorList, edit: SbiTopSectorEdit },
    { name: 'sbi-sectie', publicName: 'Sbi sectie', list: SbiSectieList, edit: SbiSectieEdit },
    { name: 'toeristen-gebied', publicName: 'Toeristengebied', list: ToeristenGebiedList, edit: ToeristenGebiedEdit },
    { name: 'veiligheids-regio', publicName: 'Veiligheidsregio',
        list: VeiligheidsRegioList, edit: VeiligheidsRegioEdit },

];
const constantRoutes: RouteConfig[] = [];
for (const constantCrudRoute of constantCrudRoutes) {
    constantRoutes.push({
        path: '/' + constantCrudRoute.name,
        name: constantCrudRoute.name,
        components: {
            default: constantCrudRoute.list,
        },
        meta: {
            publicName: constantCrudRoute.publicName,
            roles: [],
            indexRouteName: constantCrudRoute.name,
            editRouteName: constantCrudRoute.name + 'Edit',
            isConstantRoute: true,
        },
    });
    constantRoutes.push({
        path: '/' + constantCrudRoute.name + '/nieuw',
        name: constantCrudRoute.name + 'Create',
        components: {
            default: constantCrudRoute.edit,
        },
        meta: {
            publicName: constantCrudRoute.publicName + ' toevoegen',
            roles: [],
            indexRouteName: constantCrudRoute.name,
            editRouteName: constantCrudRoute.name + 'Edit',
            isConstantRoute: true,
        },
    });
    constantRoutes.push({
        path: '/' + constantCrudRoute.name + '/:id',
        name: constantCrudRoute.name + 'Edit',
        components: {
            default: constantCrudRoute.edit,
        },
        meta: {
            publicName: constantCrudRoute.publicName + ' bewerken',
            roles: [],
            indexRouteName: constantCrudRoute.name,
            editRouteName: constantCrudRoute.name + 'Edit',
            isConstantRoute: true,
        },
    });
}

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            component: AppBase,
            meta: {requiresAuth: true},
            children: [
                {
                    path: '/',
                    name: 'home',
                    component: Home,
                },
                ...users,
                ...constantRoutes,
                ...vestigingen,
                ...kvk,
                ...kvkSignals,
                ...kvkSignalVestiging,
                ...registers,
                ...cbs,
                ...importTemplates,
                ...imports,
                ...exports,
                ...mutaties,
                ...bag,
                ...shape,
                // TODO/FIXME: make sure the reset-data logic has been removed before pushing data to live.
                {
                    path: '/reset-data',
                    name: 'reset-data',
                    component: ResetData,
                },
            ],
        },
        {
            path: '/mfa-manage',
            name: 'mfa-manage',
            components: {
                default: MfaManage,
            },
            meta: {publicName: '2FA Beheren', roles: [], indexRouteName: 'mfa-manage'},
        },
        {
            path: '/login',
            name: 'login',
            component: Login,
        },

        {
            path: '/mfa-login',
            name: 'mfa-login',
            component: MfaLogin,
        },

        {
            path: '/access-denied',
            name: 'access-denied',
            component: AccessDenied,
        },
    ],
});
