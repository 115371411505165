import Vue from 'vue';
import VueI18n from 'vue-i18n';
import {http, get} from '../utils/http';
import {locale as dayjslocale} from 'dayjs';

Vue.use(VueI18n);

// tslint:disable-next-line:no-var-requires
const fallback = require('../../public/locale/messages.nl.json');

// start with 'en' synchronous
// load other locales async

const i18n = new VueI18n({
    locale: 'nl',
    messages: {nl: fallback},
    fallbackLocale: 'nl',
});

export default i18n;

export let $t = i18n.t.bind(i18n);
export let $te = i18n.te.bind(i18n);

const loadedLocales = ['nl'];

export function setI18nLocale(locale: string) {
    i18n.locale = locale;
    http.defaults.headers.common['Accept-Language'] = locale;
    document.querySelector('html')!.setAttribute('lang', locale);

    dayjslocale(locale);
}

export async function loadI18nMessages(locale: string) {
    locale = locale.toLowerCase().replace(/[^a-z]/g, '');
    if (locale === i18n.locale) {
        return Promise.resolve(setI18nLocale(locale));
    }

    if (loadedLocales.indexOf(locale) !== -1) {
        return Promise.resolve(setI18nLocale(locale));
    }

    try {
        const response = await get(`/locale/messages.${locale}.json`);
        let messages: any;
        if (typeof response.data === 'string') {
            messages = JSON.parse(response.data);
        } else if (typeof response.data === 'object') {
            messages = response.data;
        }

        if (messages) {
            i18n.setLocaleMessage(locale, messages);
            loadedLocales.push(locale);
            setI18nLocale(locale);
        }
    } catch (e) {
        // intentionally left empty
    }
}
